import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useContext,
  useRef,
  useState,
} from 'react'

import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

import { updateRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { checkUsernameAction } from 'actions/registration/checkUsernameAction'
import {
  BottomSheet,
  useBottomSheet,
} from 'components/designSystem/BottomSheet/BottomSheet'
import { OnboardingLayout } from 'components/designSystem/layout/OnboardingLayout/OnboardingLayout'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { StepRegistrationFieldList } from 'reducers/registration/stepRegistrationReducer'

import { useCloseCookiesBanner } from './hooks/useCloseCookiesBanner'
import { InputWithQuestionButton } from './InputWithQuestionButton'
import { NameInvalidReasons } from './NameInvalidReasons'
import { NameRulesButton } from './NameRulesButton'
import { NextStepButton } from './NextStepButton'
import { OnboardingContext } from './Onboarding.context'
import { enterBirthdayPath } from './paths'
import { StepCommonProps } from './types'
import { ErrorName } from '../DatingProfile/components/ErrorName'

export const EnterNameStep: FC<StepCommonProps> = ({ active }) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [errorCode, setErrorCode] = useState('')

  const { ref, open: openBottomSheet } = useBottomSheet()
  const { bottomSheetPortal } = useContext(OnboardingContext)

  useCloseCookiesBanner(active)

  const { name, locale } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { name },
      },
      systemReducer: { locale },
    }) => ({ name, locale })
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(
      updateRegistrationFieldAction(
        StepRegistrationFieldList.name,
        event.target.value.trimStart()
      )
    )
    setErrorCode('')
  }

  const hasName = name.length > 0

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && hasName) {
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    if (loading) {
      return
    }

    setLoading(true)
    const result = await dispatch(checkUsernameAction(name))
    setLoading(false)

    if (!result.ok && result.error.code) {
      setErrorCode(result.error.code)
      return
    }

    dispatch(push(mergeAllUrls(locale, enterBirthdayPath)))
  }

  return (
    <OnboardingLayout
      title={
        <FormattedMessage
          id="app.what.is.your.name"
          defaultMessage="Как тебя зовут?"
        />
      }
      subtitle={
        <FormattedMessage
          id="app.what.is.your.name_description"
          defaultMessage="Будь собой в выборе имени —{br}больше внимания получают реальные профили."
          values={{ br: <br /> }}
        />
      }
    >
      <InputWithQuestionButton
        name="user-name"
        ref={inputRef}
        placeholder={intl.formatMessage(lexemes.placeholder)}
        value={name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        errorText={errorCode ? <ErrorName error={errorCode} /> : ''}
        required
        disabled={!active}
        elementRight={<NameRulesButton onClick={openBottomSheet} />}
      />

      <NextStepButton
        visible={active}
        active={hasName}
        loading={loading}
        onClick={handleSubmit}
      />

      <BottomSheet
        ref={ref}
        title={<FormattedMessage id="app.rules" defaultMessage="Правила" />}
        // Нужно вынести из этого места в дом-дереве,
        // чтобы заголовок с прогресс баром не перекрывал правила на десктопе
        desktopPortal={bottomSheetPortal}
      >
        <NameInvalidReasons />
      </BottomSheet>
    </OnboardingLayout>
  )
}

const lexemes = defineMessages({
  placeholder: {
    id: 'app.your_name',
    defaultMessage: 'Твоё имя',
  },
})
