import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useMemo,
  useRef,
  useState,
} from 'react'

import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { updateRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { validateBirthdayAction } from 'actions/registration/validateBirthdayAction'
import { OperationSystem } from 'common-constants/bowser'
import { Input } from 'components/designSystem/Input/Input'
import { OnboardingLayout } from 'components/designSystem/layout/OnboardingLayout/OnboardingLayout'
import { resolveFormFieldValue } from 'components/presentational/form'
import { createDateFromStrings } from 'functions/date/createDateFromStrings'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { addZeroBeforeTimeUnit } from 'functions/number/addZeroBeforeTimeUnit'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { StepRegistrationFieldList } from 'reducers/registration/stepRegistrationReducer'

import { truncateDateValueToMaxLength } from './functions/truncateDateValueToMaxLength'
import { NextStepButton } from './NextStepButton'
import { selectDatingPurposePath } from './paths'
import { StepCommonProps } from './types'

export const EnterBirthdayStep: FC<StepCommonProps> = ({ active }) => {
  const dispatch = useAppDispatch()
  const { name, day, month, year, osName, locale } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { name, day, month, year },
      },
      systemReducer: { osName, locale },
    }) => ({ name, day, month, year, osName, locale })
  )

  const inputDayRef = useRef<HTMLInputElement>(null)
  const inputMonthRef = useRef<HTMLInputElement>(null)
  const inputYearRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)
  const [inputsNode, setInputsNode] = useState<HTMLDivElement | null>(null)

  // Используется input type="date" для мобильной версии
  const inputDateEnabled =
    osName === OperationSystem.Android || osName === OperationSystem.Ios

  /** Обработчик для десктопной версии (ручной ввод) */
  const handleInputNumberChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (inputDateEnabled) {
      // Используется input type="date" для мобильной версии,
      // поэтому подстраховываемся и не пускаем дальше
      return
    }

    const { name, value } = resolveFormFieldValue(event.target)

    const _value = value as string

    dispatch(
      updateRegistrationFieldAction(
        name as StepRegistrationFieldList,
        truncateDateValueToMaxLength(name, _value)
      )
    )

    switch (name) {
      case StepRegistrationFieldList.day:
        if (_value.length >= 2) {
          inputMonthRef.current?.focus()
        }
        break
      case StepRegistrationFieldList.month:
        if (_value.length >= 2) {
          inputYearRef.current?.focus()
        }
        break

      default:
        break
    }
  }

  /** Обработчик для мобильной версии (дейтпикер) */
  const handleDateInputChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const date = new Date(event.target.value)

    dispatch(
      updateRegistrationFieldAction(
        StepRegistrationFieldList.day,
        String(date.getDate())
      )
    )
    dispatch(
      updateRegistrationFieldAction(
        StepRegistrationFieldList.month,
        String(date.getMonth() + 1)
      )
    )
    dispatch(
      updateRegistrationFieldAction(
        StepRegistrationFieldList.year,
        String(date.getFullYear())
      )
    )
  }

  const isDateValid = useMemo(
    () => Boolean(createDateFromStrings(day, month, year)),
    [day, month, year]
  )

  const handleNextStepButtonClick = async () => {
    setLoading(true)
    const _month = addZeroBeforeTimeUnit(Number(month))
    const _day = addZeroBeforeTimeUnit(Number(day))
    const birthday = `${year}-${_month}-${_day}`

    const response = await dispatch(validateBirthdayAction(birthday))

    if (response.ok) {
      dispatch(push(mergeAllUrls(locale, selectDatingPurposePath)))
    }

    setLoading(false)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && isDateValid) {
      handleNextStepButtonClick()
    }
  }

  const disabled = !active
  return (
    <OnboardingLayout
      title={
        <FormattedMessage
          id="app.enter.your.birthday"
          defaultMessage="{name}, выбери дату рождения"
          values={{ name: <Name>{name}</Name> }}
        />
      }
      subtitle={
        <FormattedMessage
          id="app.use.you.real.birthday"
          defaultMessage="Используй настоящую, потом изменить её будет нельзя."
        />
      }
    >
      <Inputs ref={setInputsNode}>
        <InputDay
          ref={inputDayRef}
          name={StepRegistrationFieldList.day}
          type="number"
          placeholder="01"
          min="1"
          max="31"
          value={day}
          onChange={handleInputNumberChange}
          onKeyDown={handleKeyDown}
          required
          disabled={disabled}
          label={
            <FormattedMessage id="form.select.day" defaultMessage="День" />
          }
        />
        <InputMonth
          ref={inputMonthRef}
          name={StepRegistrationFieldList.month}
          type="number"
          placeholder="01"
          min="1"
          max="12"
          value={month}
          onChange={handleInputNumberChange}
          onKeyDown={handleKeyDown}
          required
          disabled={disabled}
          label={
            <FormattedMessage id="form.select.month" defaultMessage="Месяц" />
          }
        />
        <InputYear
          ref={inputYearRef}
          name={StepRegistrationFieldList.year}
          type="number"
          placeholder="2005"
          min="1900"
          max="2100"
          value={year}
          onChange={handleInputNumberChange}
          onKeyDown={handleKeyDown}
          required
          disabled={disabled}
          label={
            <FormattedMessage id="form.select.year" defaultMessage="Год" />
          }
        />
        {inputDateEnabled && (
          <HiddenInputDate
            type="date"
            // Для iOS нужно явно указать размер, иначе поле ввода не растянется
            inputStyle={{
              width: inputsNode?.clientWidth,
              height: inputsNode?.clientHeight,
            }}
            onChange={handleDateInputChange}
            disabled={disabled}
            inputInnerCss={inputInnerCss}
          />
        )}
      </Inputs>
      <NextStepButton
        visible={active}
        loading={loading}
        active={isDateValid}
        onClick={handleNextStepButtonClick}
      />
    </OnboardingLayout>
  )
}

export const Name = styled.span`
  word-break: break-all;
`
const Inputs = styled.div`
  display: flex;
  position: relative;

  input {
    text-align: center;
  }

  & > * + * {
    html[dir='ltr'] & {
      margin-left: 12px;
    }

    html[dir='rtl'] & {
      margin-right: 12px;
    }
  }
`
const InputDay = styled(Input)`
  width: 64px;
`
const InputMonth = styled(Input)`
  width: 64px;
`
const InputYear = styled(Input)`
  width: 98px;
`
const HiddenInputDate = styled(Input)`
  width: 100%;
  text-align: center;
  margin: 0;
  position: absolute;
  inset: 0;
  opacity: 0;
`
const inputInnerCss = css`
  height: 100%;
`
